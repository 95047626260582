
const employeeValidFromInput = document.querySelectorAll(".employee-valid-from");
const employeeValidUntilInput = document.querySelectorAll(".employee-valid-until");
const customerValidFromInput = document.querySelectorAll(".customer-valid-from");
const customerValidUntilInput = document.querySelectorAll(".customer-valid-until");

function setInputValues (elementValue, nodes) {
  nodes.forEach((el) => {
    el.value = elementValue;
  });
}

function setEventlisteners (inputFields) {
  inputFields.forEach((element) => {
    element.addEventListener("change", () => {
      setInputValues(element.value, inputFields);
    });
  });
}

setEventlisteners(employeeValidFromInput);
setEventlisteners(employeeValidUntilInput);
setEventlisteners(customerValidFromInput);
setEventlisteners(customerValidUntilInput);
